body{
  display:flex;
  justify-content:center;
  align-items:center;
  height:100vh;
  background-color:#282c34;
  color:white;
  text-align:center;
}

input{
  padding:10px;
  margin:10px;
  border-radius:5px;
  border:none;
}

.green{

  color:green;
}
.red{
  color:red;
}
.grid{
  display: grid;
}

button{
  padding:10px;
  margin:10px;
  border-radius:5px;
  border:none;
  background-color:#61dafb;
  color:black;
  cursor:pointer;
}


